<script setup lang="ts">
const { $Parse } = useNuxtApp()

const address = ref('')

;(async function init() {
  const res = await $Parse.Cloud.run('GetCompanyData', {
    companyId: 'Ce38wQCrFP',
    fields: 'address'
  })
  address.value = `${res.address1} ${res.address2}`
})()
</script>

<template>
  <footer>
    <div class="inner">
      <div class="head">
        <h3>
          <a
            href=""
            @click.prevent="$linker.push('/share/introduction/Ce38wQCrFP/1')">
            주식회사 질링스
          </a>
        </h3>
        <p>비즈니스 올인원 플랫폼</p>
      </div>

      <div class="body">
        <p>대표자: 양홍석</p>
        <p>사무실: {{ address ? address : '-' }}</p>
        <p>이메일: contact@zillinks.com</p>
        <div class="terms">
          <a
            href="https://www.notion.so/914d2215cb3d4eac93e62c9b176f9d0c"
            target="_blank">
            개인정보처리방침
          </a>
          <a
            href="https://www.notion.so/ec9b6aedf0bc473493ca6061fe8b156a"
            target="_blank">
            이용약관
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
footer {
  background-color: #222437;
  .inner {
    padding: 40px 26px;
    border-top: 1px solid #3e446e;
    font-size: 14px;
    line-height: 1.3;
    color: #9397bf;
    p {
      margin-bottom: 6px;
    }
    .head {
      margin-bottom: 24px;
      h3 {
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 700;
        color: #b7bad7;
        text-decoration: underline;
        cursor: pointer;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .body {
      .terms {
        display: flex;
        gap: 14px;
        margin-top: 10px;
        font-weight: 600;
        a {
          display: block;
          margin-bottom: 10px;
          color: #9397bf;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
