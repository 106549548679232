<script setup lang="ts">
import TheHeader from '~/components/TheHeader.vue'
import TheFooter from '~/components/TheFooter.vue'

if (process.client) document.body.style.backgroundColor = '#222437'
</script>

<template>
  <TheHeader />
  <NuxtPage />
  <TheFooter />
</template>
